export default defineNuxtRouteMiddleware(async (to, _from) => {
  const { user } = useUser();
  const activeTeam = useActiveTeam();

  if (!user.value?.isOnAdminTeam) {
    return navigateTo(
      `teams/${activeTeam.value?.id}${to.fullPath.replace("/admin", "")}`
    );
  }
});
